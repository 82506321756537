     <template>
       <v-container class="grey lighten-5">
         <template>
          <v-container fluid>
            <v-layout row>
              <v-flex xs4>     
                <v-layout column>         
                  <v-img src="" aspect-ratio="4"></v-img>       
                </v-layout>
                <h2>NUPC 18-  0 2 9 9 9 9 1</h2>             
              </v-flex>
              <v-flex xs5>
                <h1 align="center">National University</h1> 
                <h3 align="center">Gazipur - 1704,Bangladesh</h3> 
              </v-flex> 
              <v-flex xs4  offset-sm2>
                <h2 >09-11-1-55860</h2> 
              </v-flex>            
            </v-layout>          
            <br>
            <v-layout row>
              <v-flex xs4>     
              </v-flex>
              <v-flex xs5>
                <v-img src="" aspect-ratio="4"></v-img> 
              </v-flex> 
              <v-flex xs4  offset-sm2>
                <h2 >Roll NO:</h2>
                <h2>Regn.No:</h2>
                <h2>Session:</h2>  
              </v-flex>            
            </v-layout>         
            <br>
            <v-layout row>
              <v-flex xs4>     
              </v-flex>
              <v-flex xs5>
                <h2 align="center">PROVISIONAL CERTIFICATE</h2>
                <h2 align="center">Bachelor Of Arts</h2>
                <h3 align="center">This is to certify that</h3>
              </v-flex> 
              <v-flex xs4  offset-sm2>                
              </v-flex>            
            </v-layout>
            <v-flex xs12>
              <v-text-field

              ></v-text-field>
            </v-flex>
            <v-layout row>
              <v-flex xs1 class="title" >
                <div>of</div>
              </v-flex>
              <v-flex xs11>
                <v-text-field

                ></v-text-field>
              </v-flex>
            </v-layout>
             <v-layout row>
              <v-flex xs3 class="title" >
                <div>obtained the degree of</div>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                ></v-text-field>
              </v-flex>
              <v-flex xs3 class="title" >
                <div>(Four Year)With Honours</div>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs1 class="title" >
                <div>in</div>
              </v-flex>
              <v-flex xs7>
                <v-text-field
                ></v-text-field>
              </v-flex>
              <v-flex xs4 class="title" >
                <div>under this university in 2009 and</div>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs3 class="title" >
                <div>that he/she was placed in the</div>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                ></v-text-field>
              </v-flex>
              <v-flex xs2 class="title" >
                <div>class.</div>
              </v-flex>
            </v-layout>
            <br><br><br>
            <v-layout row>
             <v-list-item-content>
              <v-list-item-title>University Building</v-list-item-title><br>
              <v-list-item-title>Gazipur,Bangladesh</v-list-item-title><br>
              <v-list-item-title>Date:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-layout>
      </v-container>
    </template>


  </v-container>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>